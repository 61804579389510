import Image from "../components/Image/Image";

export function FWWRAbout({ tracker }) {
  if (!tracker) {
    return null;
  }

  return (
    <div className="col-span-8 mb-24">
      <a href="https://fwwr.org" className="p-3 inline-block">
        Back to fwwr.org
      </a>
      <div className="bg-white p-4 py-24 shadow-md md:rounded-2xl">
        <div className="mx-auto max-w-4xl">
          <h2 className="mb-4 text-2xl font-bold text-slate-900">About us</h2>

          <Image
            alt="header"
            sourceKey={tracker.headerImageKey}
            className="mb-4 max-h-40 w-full rounded-md rounded-t-lg object-cover object-center md:max-h-72"
          />

          <p className="mb-4 font-serif text-slate-700">
            Forever Wild Wildlife Rescue operates under the supervision of the
            Oregon Department of Fish and Wildlife and the U.S. Fish and
            Wildlife Service, and Veterinarian Dr. Tammy Eichmann of Countryside
            Veterinary Service (located in Canyonville and Myrtle Creek). We
            presently consist of four licensed wildlife rehabilitators — with
            experience ranging up to 24 years— and six sub-permittees. We are
            licensed to rehabilitate birds, raptors and mammals. We have a
            support team that staffs a Hotline, which receives calls of injured
            or orphaned wildlife and arranges for their placement. Others serve
            as a recovery team, where they rescue and transport wildlife to
            waiting rehabilitators. We also have members who serve at
            educational events, engage in fundraising activities and construct
            wildlife pens.
          </p>
        </div>
      </div>
    </div>
  );
}
