export default function componentLoader(lazyComponent, attemptsLeft = 3) {
  return new Promise((resolve, reject) => {
    lazyComponent().then(resolve).catch(error => {
      // Retry after 1 second
      setTimeout(() => {
        if (attemptsLeft === 1) {
          return reject(error);
        }

        componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
      }, 1000);
    });
  });
};
