import React from "react";
import ReactGA from "react-ga";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./css/tailwind.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import App from "./components/App";

const rootElement = document.getElementById("root");
const { host } = window.location;
const devENV = [":3000", ":3001", "herokuapp.com"].some((item) =>
  host.includes(item)
);

Sentry.init({
  dsn: "https://f958c6eb900648c88038566c3ba8f0be@o560389.ingest.sentry.io/5695934",
  environment: devENV ? "development" : "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1
});

ReactGA.initialize("UA-135023550-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const root = createRoot(rootElement);
root.render(<App />);
