import Image from "../components/Image/Image";

export function UFEDAbout({ tracker }) {
  if (!tracker) {
    return null;
  }

  return (
    <div className="col-span-8 mb-12">
      <div className="bg-white p-4 py-8 md:rounded-2xl">
        <div className="mx-auto max-w-2xl">
          <h2 className="mb-4 text-2xl font-bold text-slate-900">About us</h2>

          <p className="mb-4 font-serif text-slate-700">
            The Umpqua Fishery Enhancement Derby (UFED) has become one of the
            premier events of its kind in the Pacific Northwest. All money
            raised goes toward fish enhancement/restoration projects and fish
            educational opportunities in the Umpqua Basin. UFED has contributed
            more than $1.9 million in matching funds toward hundreds of projects
            over the past thirty years. The 2022 Derby event alone raised more
            than $100,000 to invest in the Umpqua.
          </p>

          <Image
            alt="header"
            sourceKey={tracker.headerImageKey}
            className="mb-4 max-h-40 w-full rounded-md rounded-t-lg object-cover object-center md:max-h-72"
          />

          <p className="mb-4 font-serif text-slate-700">
            The Derby began as two-day guided fishing trips with about 75 local
            participants attending a dinner and auction in a small motel
            conference room and raised $7000 for projects. Over the past 30
            years UFED has grown into a popular four-day event with hundreds of
            sponsors and donors from all around the state joining us in support
            of Umpqua Basin fish populations and angling opportunities. The UFED
            Dinner/Auction and Derby Fishing Teams are sold-out early, with more
            sponsors always welcome.
            <br />
            <br />
            UFED is proud of the gracious funding-support dedicated to the
            hundreds of projects that have been completed in the Umpqua basin
            and are excited to reach the 2-million- dollar goal of donated funds
            since 1993 to continue these enhancement and education activities.
            <br />
            <br />
            Each year our Derby event runs from Wednesday through Saturday the
            end of January/first of February. The event begins with "Student
            Day: Project Fish" on Wednesday where 25-30 high school students
            spend a day on a Rock Creek watershed tour with local natural
            resources specialists. "Kids Day" is on Thursday where fifth grade
            students from a local elementary school are treated to a morning of
            fishing on Cooper Creek Reservoir with our Derby Guides, North River
            jet boat rides, and outdoor lunch as recognition for a hatchery
            program on their school campus and year-round lessons about fish.
            The catch-and-release winter steelhead fishing takes place on Friday
            and Saturday on the North, South, and Main Umpqua rivers. The Derby
            draws fishermen from throughout Oregon and the best fishing guides
            in Southern Oregon. Thursday evening is a Crab Feed and raffle for
            the “Fishing Teams” and their sponsors.
            <br />
            <br />
            Our annual UFED Banquet and Auction takes place on Friday evening at
            the Fairgrounds. This has become one of the most popular events in
            Douglas County with an attendance of over 600 people. This is the
            major fund-raising event for our projects and offers the attendees
            many opportunities to bid on several oral and silent auction items,
            raffle items, and always enjoy a fun gathering with great food and
            drinks.
            <br />
            <br />
            The 2023 Umpqua Fishery Enhancement Derby is February 1st-4th. If
            you would like more information about UFED and our projects, please
            contact Tina Roy by emailing tina@dougtimber.org or call (541)
            672-0757
            <br />
            <br />
            <hr />
            <br />
            <span className="text-sm italic">
              This UFED video project is not affiliated with or supported by
              Oregon Department of Fish and Wildlife and its official agency
              fish counts estimates. Contact the ODFW Roseburg office
              (541-440-3353) regarding questions about the Winchester dam fish
              ladder and public viewing area.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
