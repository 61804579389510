import React, { Component } from "react";
import classNames from "classnames";
import queryString from "query-string";

class Image extends Component {
  state = {
    loaded: false
  }

  render() {
    const { loaded } = this.state;
    const { host } = window.location;
    const props = { ...this.props };
    const devHosts = [":3000", ":3001", "herokuapp.com", "ngrok.io"];
    let env = (devHosts.find(name => host.includes(name)) && "development") || "production";
    env = "production";

    const domain = env === "production" ? "tracker.imgix.net" : "tracker-dev.imgix.net";
    const query = {
      fit: "crop",
      auto: "compress"
    };

    if (!props.sourceKey && props.imageURL) {
      props.sourceKey = props.imageURL.split("/").filter((id, index) => index > 2).join("/");
    }

    // Default value for sourceKey
    props.sourceKey = props.sourceKey || "placeholder-profile.jpg";

    if (props.resize) {
      if (props.resize.width) {
        query.w = props.resize.width;
        query.h = props.resize.height;
      }
    }

    const htmlProps = { ...props };
    delete htmlProps.resize;
    delete htmlProps.imageURL;
    delete htmlProps.sourceKey;
    delete htmlProps.sourceBucket;

    return (
      <>
        <img
          {...htmlProps}
          alt={props.alt || ""}
          onLoad={() => this.setState({ loaded: true })}
          className={classNames(props.className, !loaded && "hide")}
          src={`https://${domain}/${props.sourceKey}${query ? "?" + queryString.stringify(query) : ""}`}
        />

        {/* Show a low res photo on slow connections while the high res photo loads */}
        {!loaded &&
          <img
            {...htmlProps}
            alt={props.alt || ""}
            src={`https://${domain}/${props.sourceKey}?q=50&w=30&blur=10`}
          />
        }
      </>
    );
  }
}

// class Image extends Component {
//   state = {
//     loaded: false
//   }

//   render() {
//     const { loaded } = this.state;
//     const { host } = window.location;
//     const props = {
//       ...this.props
//     };

//     // Choose the bucket to get images from
//     const bucket = host.includes(":3000") || host.includes(":3001") || host.includes("herokuapp.com") || host.includes("ngrok.io") ? "join-tracker-development" : "join-tracker";
//     const cloudFrontUrl = "https://d3ugunli1jxk3c.cloudfront.net";

//     // Set default value for sourceKey and alt
//     props.sourceKey = props.sourceKey || "placeholder-profile.jpg";
//     props.alt = props.alt || "";

//     // Low res photo URL
//     let lowRes = null;

//     // If the image should be resized before retrieving it
//     if (props.sourceKey) {
//       const highResRequest = {
//         bucket: props.sourceBucket || bucket,
//         key: props.sourceKey,
//       };
//       const lowResRequest = {
//         bucket: props.sourceBucket || bucket,
//         key: props.sourceKey,
//       };

//       // Resize the image
//       if (props.resize && (props.resize.width || props.resize.height)) {
//         let ratio = null;

//         if (props.resize.width && props.resize.height) {
//           ratio = Math.min(10 / props.resize.width, 10 / props.resize.height);
//         }

//         highResRequest.edits = {
//           resize: {
//             fit: "cover"
//           }
//         };

//         lowResRequest.edits = {
//           resize: {
//             fit: "cover"
//           }
//         };

//         if (props.resize.width) {
//           highResRequest.edits.resize.width = props.resize.width;
//           lowResRequest.edits.resize.width = ratio ? Math.floor(props.resize.width * ratio) : 10;
//         }

//         if (props.resize.height) {
//           highResRequest.edits.resize.height = props.resize.height;
//           lowResRequest.edits.resize.height = ratio ? Math.floor(props.resize.height * ratio) : 10;
//         }
//       }

//       const lowResRequestString = JSON.stringify(lowResRequest);
//       const highResRequestString = JSON.stringify(highResRequest);

//       lowRes = `${cloudFrontUrl}/${btoa(lowResRequestString)}`;
//       props.src = `${cloudFrontUrl}/${btoa(highResRequestString)}`;

//       delete props.alt;
//       delete props.resize;
//       delete props.sourceKey;
//       delete props.sourceBucket;
//     }

//     const lowResImageClass = props.className;
//     const highResImageClass = classNames(props.className, loaded === false ? "hide" : "");

//     delete props.className;

//     return (
//       <>
//         <img
//           {...props}
//           alt={props.alt}
//           className={highResImageClass}
//           onLoad={() => this.setState({ loaded: true })}
//         />

//         {/* Show a low res photo on slow connections while the high res photo loads */}
//         {!loaded &&
//           <img
//             {...props}
//             src={lowRes}
//             alt={props.alt}
//             className={lowResImageClass}
//           />
//         }
//       </>
//     );
//   }
// }

export default Image;
