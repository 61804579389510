import React, { Component } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// Bootstrap
import Navbar from "react-bootstrap/Navbar";

// Custom
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import MobileNavFlyout from "./MobileNavFlyout";

class Navigation extends Component {
  state = {
    visible: true,
    navShow: false,
    showMobileMenu: false,
    windowWidth: window.innerWidth,
    prevScrollPosition: window.pageYOffset,
  }

  toggleMobileMenu = show => {
    this.setState(state => ({
      showMobileMenu: (typeof show === "boolean" ? show : !state.showMobileMenu)
    }));
  }

  // Hide nav on scroll for mobile
  handleScroll = () => {
    const {
      prevScrollPosition,
      navShow
    } = this.state;

    const currentScrollPosition = window.pageYOffset;
    const visible = prevScrollPosition > currentScrollPosition;

    this.setState(prev => ({
      prevScrollPosition: window.pageYOffset % 20 === 0 ? currentScrollPosition : prev.prevScrollPosition,
      visible: navShow || window.pageYOffset <= 20 ? true : visible
    }));
  }

  handleWindowResize = ({ target }) => {
    const root = document.getElementById("root");
    const disabledTopSpacePages = ["/newsletter", "/publish", "/login"];
    let isDisabledSpacePage = false;

    disabledTopSpacePages.forEach(page => {
      if (window.location.pathname.includes(page)) {
        isDisabledSpacePage = true;
      }
    });

    this.setState({
      windowWidth: target.innerWidth
    });

    if (!isDisabledSpacePage) {
      if (target.innerWidth <= 767) {
        if (root.className !== "bottom-space") {
          root.className = "bottom-space";
        }
      } else {
        if (root.className !== "top-space") {
          root.className = "top-space";
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);

    const { windowWidth, showMobileMenu } = this.state;
    const root = document.getElementById("root");
    const navOverlay = document.getElementsByClassName("main-ui-nav-overlay")[0];

    // Prevent scrolling on nav show
    if (showMobileMenu) {
      disableBodyScroll(navOverlay);
    }

    if (windowWidth <= 767) {
      if (root.className !== "bottom-space") {
        root.className = "bottom-space";
      }

      window.addEventListener("scroll", this.handleScroll);
    } else {
      if (root.className !== "top-space") {
        root.className = "top-space";
      }
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleWindowResize);
    clearAllBodyScrollLocks();
  }

  hideNavDropdown = () => this.setState({ navShow: false });

  componentDidUpdate() {
    const { showMobileMenu } = this.state;
    const navOverlay = document.getElementsByClassName("main-ui-nav-overlay")[0];

    // Prevent scrolling on nav show
    if (showMobileMenu) {
      disableBodyScroll(navOverlay);
    } else {
      clearAllBodyScrollLocks();
    }
  }

  isIOS = () => [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod"
  ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

  render() {
    const {
      user,
      tracker,
      location,
      trackerSubdomain,
      ifTrackerSubdomain
    } = this.props;
    const {
      visible,
      navShow,
      windowWidth,
      showMobileMenu,
    } = this.state;
    const title = tracker ? tracker.displayName : "Tracker";

    return (
      <>
        {windowWidth < 768 && showMobileMenu && <MobileNavFlyout
          user={user}
          tracker={tracker}
          showMobileMenu={showMobileMenu}
          trackerSubdomain={trackerSubdomain}
          toggleMobileMenu={this.toggleMobileMenu}
        />}

        <Navbar
          onSelect={this.hideNavDropdown}
          expanded={navShow}
          onToggle={() => this.setState(prev => prev.navShow = !prev.navShow)}
          expand="md"
          bg="white"
          style={{
            paddingBottom: windowWidth < 768 ? "env(safe-area-inset-bottom)" : "0"
          }}
          fixed={windowWidth < 768 ? "bottom" : "top"}
          className={"main-ui-nav card-shadow px-0 pt-0"}
        >
          {windowWidth < 768 ?
            <MobileNav
              user={user}
              title={title}
              visible={visible}
              tracker={tracker}
              location={location}
              showMobileMenu={showMobileMenu}
              hideNavDropdown={this.hideNavDropdown}
              toggleMobileMenu={this.toggleMobileMenu}
              trackerSubdomain={trackerSubdomain}
              ifTrackerSubdomain={ifTrackerSubdomain}
            />
          :
            <DesktopNav
              user={user}
              title={title}
              tracker={tracker}
              location={location}
              trackerSubdomain={trackerSubdomain}
              ifTrackerSubdomain={ifTrackerSubdomain}
            />
          }
        </Navbar>
      </>
    );
  }
}

export default Navigation;
