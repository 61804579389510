import React, { Component } from "react";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ConnectionError extends Component {
  setLocalError(key, expire) {
    localStorage.setItem(key, JSON.stringify({
      error: key,
      expire: new Date().getTime() + expire
    }));
  }

  getLocalError(key) {
    const errorString = window.localStorage.getItem(key);

    if (!errorString) {
      return null;
    }

    const error = JSON.parse(errorString);
    const hasExpired = new Date().getTime() > error.expire;

    if (hasExpired) {
      localStorage.removeItem(key);
      return null;
    }

    return true;
  }

  componentDidMount() {
    if (!this.getLocalError("chunk_failed")) {
      this.setLocalError("chunk_failed", 10000);

      window.location.reload();
    } else {
      const root = document.getElementById("root");
      root.className = "";
      document.body.style.backgroundColor = "#fff";
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = "#f4f7fb";
  }

  render() {
    return (
      <Container className="vh-100 d-flex align-items-center justify-content-around">
        <Row className="w-100">
          <Col md={8} lg={6} className="mx-auto px-0">
            <div className="text-secondary mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0" /></svg>
            </div>

            <h2 className="font-weight-bold mb-1">Something went wrong...</h2>
            <p className="mb-5">
              You might be offline or something is isn't working right now. Please reach out to us at <a href="mailto:support@jointracker.com">support@jointracker.com</a>.
            </p>

            <button onClick={() => window.location.reload()} className="btn bg-primary w-100 text-white shadow-none d-block font-weight-medium mt-3">
              Try Again
            </button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ConnectionError;
