export function FWWRDonate() {
  return (
    <div className="col-span-8 mb-12">
      <a href="https://fwwr.org" className="p-3 inline-block">Back to fwwr.org</a>
      <div className="flex flex-col space-y-6 bg-white p-8 md:flex-row md:space-x-6 md:space-y-0 md:rounded-2xl">
        <iframe
          title="donate"
          src="https://donorbox.org/embed/general-101?default_interval=o"
          name="donorbox"
          allowpaymentrequest="allowpaymentrequest"
          seamless="seamless"
          frameborder="0"
          scrolling="no"
          width="100%"
          height="800px"
          style={{
            maxWidth: "500px",
            minWidth: "310px",
            maxHeight: "none!important",
          }}
        />

        <div className="md:p-8">
          <div className="mb-4 flex items-center space-x-2">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-pink-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-white text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div>

            <h2 className="text-2xl font-semibold tracking-tight">
              Make a donation
            </h2>
          </div>

          <p className="text-lg text-gray-500">
            Forever Wild Wildlife Rescue is a non-profit that relies on
            donations to cover its expenses. Donated funds help pay for medicine
            and medical supplies, flight cages, animal feed, vet bills, and
            various administrative costs and other expenses related to the
            wildlife rehabilitation work. Forever Wild has no buildings, rent or
            salaries as the group is operated entirely by volunteers.
            <br />
            <br />
            The mission and purpose of Forever Wild Wildlife Rescue is: to
            rescue and rehabilitate injured and orphaned native wildlife; to
            equip rehabilitators with the appropriate and necessary resources
            and facilities; to teach and train new rehabilitators and
            volunteers; to research and learn new methods of treatment and care;
            to financially support veterinarian services for animals in serious
            need of such care; to educate the community about interactions with
            wildlife; and ultimately, to return rehabilitated wildlife in a wild
            condition to their native habitat.
            <br />
            <br />
            You're support is greatly appreciated!
          </p>
        </div>
      </div>
    </div>
  );
}
