import axios from "axios";
import io from "socket.io-client";
const socket = io();

// Initialize an API instance of axios
const api = axios.create({
  baseURL: "/api"
});

/** Web Sockets **/

// Get post processing progress
export const getPostProcessingStatus = cb => socket.on("postFileProgress", data => cb(null, data));

// Remove post processing status listener
export const removePostStatusListener = () => socket.off("postFileProgress");

// Listen on specific socket events
export const socketListener = (event, cb) => socket.on(event, data => cb(null, data));

// Remove profile image processing status listener
export const removeSocketListener = event => socket.off(event);

// Get socket client id
export const getSocketId = () => {
  console.log(socket)
  if (socket.connected) {
    return socket.id;
  } else {
    return null;
  }
};

/** Tracker Routes **/

// Get a Tracker
export const getTracker = trackerName => api.get(`/trackers/t/${trackerName}`);

// Get user Trackers
export const getUserTrackers = () => api.get("/trackers/current-user-trackers");

// Get all public Trackers
export const getAllTrackers = () => api.get("/trackers/all");

// Get all public Trackers
export const getAllPublicTrackers = () => api.get("/trackers/all-public");

// Signup for a group's newsletter
export const trackerEmailSignup = (tracker, body) => api.post(`/trackers/t/${tracker}/newsletter/subscribe`, body);

// Verify a user's email address
export const trackerEmailVerify = (tracker, token) => api.get(`/trackers/t/${tracker}/newsletter/verify/${token}`);

/** User Routes **/

// Get the most recent job posts
export const getNearPrice = () => api.get("/users/crypto/near-price");

// Get the most recent job posts
export const getJobs = () => api.get("/users/t/roseburg/jobs");

// Get the most recent job posts
export const getJob = id => api.get(`/users/t/roseburg/jobs/${id}`);

// Get the current authenticated user
export const getUser = trackerName => api.get(`/users/current${trackerName ? `?t=${trackerName}` : ""}`);

// Get the current user's posts
export const getUserPosts = offset => api.get(`/posts/user?offset=${offset}`);

// Search the current user's posts
export const searchUserPosts = (query, offset) => api.get(`/posts/user/search?query=${query}&offset=${offset}`);

// Update the currently authenticated user
export const updateCurrentUser = update => api.post("/users/current/update", update);

// Update the currently authenticated user's email preferences for a specific tracker
// export const updateUserEmailPreferences = update => api.post("/users/current/update-email-preferences", update);

// Update the currently authenticated user's push notification id
// export const updateCurrentUserNotificationId = (update, cb) => axios.post("/api/users/current/update-notification-id", update).then(cb);

// Update the currently authenticated user's password
export const changeCurrentUserPassword = update => api.post("/users/current/change-password", update);

// Update the currently authenticated user's email
export const changeCurrentUserEmail = update => api.post("/users/current/change-email", update);

// Reset the a logged out user's password
export const resetCurrentUserPassword = update => api.post("/users/reset-password", update);

// Update the currently authenticated user's joined Trackers
export const updateCurrentUserTrackers = update => api.post("/users/current/update/trackers", update);

// Update the currently authenticated user's Tracker requests
export const updateCurrentUserTrackerRequests = update => api.post("/users/current/update/tracker-requests", update);

// Signup
export const signupUser = (tracker, body) => api.post(`/users/signup${tracker ? "?t=" + tracker : ""}`, body);

// Login
export const loginUser = body => api.post("/users/login", body);

// Logout
export const logoutUser = cb => axios.get("/users/current/logout");

export const MainLandingNewsletter = body => api.post("/users/main-landing-newsletter/subscribe", body);

// Signup for the Roseburg Tracker Newsletter
// export const RTNewsletter = body => api.post("/users/rt-newsletter/subscribe", body);

// Signup for the Hawaii Tracker Newsletter
// export const HTNewsletter = body => api.post("/users/ht-newsletter/subscribe", body);

// Signup for the BL Performance discount
export const BLPerformanceAdvertiseDiscount = body => api.post("/users/bl-performance/discount", body);

// Signup for the Appliance Repair discount
export const ApplianceRepairAdvertiseDiscount = body => api.post("/users/appliance-repair/discount", body);

// Signup for the Appliance Repair discount
export const SunriseTreeAdvertiseDiscount = (body) => api.post("/users/sunrise-tree/discount", body);;

// Signup for the Reustle discount
export const ReustleAdvertiseDiscount = body => api.post("/users/reustle/discount", body);

// Signup for the BL Performance discount
export const RuestleVoucherScan = body => api.post("/users/reustle/update/voucher-redeemed", body);

// Buy advertising
export const advertisePayment = (body, tracker) => api.post(`/users/t/${tracker}/sales/payment`, body);

// Buy sponsorship in Roseburg Tracker
export const sponsorPayment = (body, tracker) => api.post(`/users/t/${tracker}/sponsor/payment`, body);

// Buy a job listing
export const jobPostPayment = body => api.post("/users/t/roseburg/job-payment", body);

/** Post Routes **/

// Get Tracker posts for combined feed
export const getUserTrackerPosts = offset => api.get(`/posts/user-trackers?offset=${offset}`);

// Get the current Tracker posts
export const getTrackerPosts = (tracker, offset) => api.get(`/posts/t/${tracker}?offset=${offset}`);

// Get suggested posts
export const suggestedPosts = (group, exclude) => api.get(`/posts/t/${group}/suggested-posts?exclude=${exclude}`);

// Get a post
export const getPost = (postId, tracker) => api.get(`/posts/t/${tracker}/${postId}`);

// Create a post
export const newPost = (tracker, data) => api({
  method: "post",
  url: `/posts/t/${tracker}/new`,
  data,
  timeout: 10 * 60 * 1000 // 10 minutes or 600000 milliseconds
});

// Edit a post
export const editPost = (postId, data) => api({
  method: "post",
  url: `/posts/${postId}/edit`,
  data,
  timeout: 10 * 60 * 1000 // 10 minutes or 600000 milliseconds
});

// Delete a post
export const deletePost = postId => api.post(`/posts/${postId}/delete`);

// Like a post
export const likePost = postId => api.post(`/posts/${postId}/like`);

// Add a comment to a post
export const newComment = (postId, comment, parentComment) => api.post(`/posts/${postId}/comment`, { comment, parentComment });

// Like a comment
export const likeComment = (postId, commentId) => api.post(`/posts/${postId}/comments/${commentId}/like`);

// Edit a comment
export const editComment = (postId, commentId, update) => api.post(`/posts/${postId}/comments/${commentId}/edit`, update);

// Delete a comment
export const deleteComment = (postId, commentId) => api.post(`/posts/${postId}/comments/${commentId}/delete`);

/** Admin Routes **/

// Get email log
export const groupEmails = (tracker, offset) => api.get(`/t/${tracker}/admin/email-log?offset=${offset}`);

// Get posts to email
export const groupEmailPosts = (tracker, offset) => api.get(`/t/${tracker}/admin/email-posts?offset=${offset}`);

// Send an email with multiple posts
export const emailPosts = (tracker, data) => api.post(`/t/${tracker}/admin/send-email-posts`, data);

// Get all the pending posts for the current tracker
export const getPendingPosts = tracker => api.get(`/t/${tracker}/admin/pending-posts`);

// Get all the admins
export const getAdmins = tracker => api.get(`/t/${tracker}/admin/admins`);

// Get admin stats
export const getAdminStats = tracker => api.get(`/t/${tracker}/admin/admin-stats`);

// Get all the users that are a member of the current tracker
export const getUsers = (tracker, offset) => api.get(`/t/${tracker}/admin/users?offset=${offset}`);

// Search all the users that are a part of the current tracker
export const searchUsers = (tracker, offset, search) => api.get(`/t/${tracker}/admin/users/search?search=${search}&offset=${offset}`);

// Get all the user requests for the current tracker
export const getTrackerRequests = tracker => api.get(`/t/${tracker}/admin/pending-users`);

// Get analytics info for the top contributors to a tracker group
export const getTrackerVisitors = tracker => api.get(`/t/${tracker}/admin/tracker-visits`);

// Get analytics info for the top contributors to a tracker group
export const getTopPosts = (tracker, range) => api.get(`/t/${tracker}/admin/top-posts?range=${range}`);

// Update a pending post's status
export const updatePostStatus = (id, status, tracker) => api.post(`/t/${tracker}/admin/posts/${id}/status`, { status });

// Update a given user's account status
export const updateAccountStatus = (id, tracker) => api.post(`/t/${tracker}/admin/users/${id}/update/status`);

// Update a given user's account role
export const updateAccountRole = (id, role, tracker) => api.post(`/t/${tracker}/admin/users/${id}/update/role`, { role });

// Update a given user's groups
export const updateUserTrackers = (id, action, tracker) => api.post(`/t/${tracker}/admin/users/${id}/update/trackers`, action);

/** Super Admin Routes **/

// Create a new tracker
export const createNewTracker = data => api.post(`/t/null/admin/trackers/new`, data);

// Edit a specified tracker
export const editTracker = (id, update, tracker) => api.post(`/t/${tracker}/admin/trackers/${id}/edit`, update);

// Get all tracker topics
export const getTrackerTopics = tracker => api.get(`/t/${tracker}/admin/trackers/topics`);

// Get group media
export const getTrackerMedia = (tracker, offset) => api.get(`/t/${tracker}/admin/trackers/media?offset=${offset}`);

// Update tracker topic
export const updateTopics = (body, tracker) => api.post(`/t/${tracker}/admin/trackers/topics/edit`, body);

// Get all tracker topics
export const deleteTopics = (body, tracker) => api.post(`/t/${tracker}/admin/trackers/topics/delete`, body);
