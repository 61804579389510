export function TrackerFoundationDonate() {
  return (
    <div className="col-span-8 mb-12">
      <a href="https://trackerfoundation.com" className="p-3 inline-block">
        Back to trackerfoundation.com
      </a>
      <div className="flex flex-col space-y-6 bg-white p-8 shadow-md md:flex-row md:space-x-6 md:space-y-0 md:rounded-2xl">
        <iframe
          title="donate"
          src="https://donorbox.org/embed/tracker-foundation?default_interval=o"
          name="donorbox"
          allowpaymentrequest="allowpaymentrequest"
          seamless="seamless"
          frameborder="0"
          scrolling="no"
          width="100%"
          height="800px"
          style={{
            maxWidth: "500px",
            minWidth: "310px",
            maxHeight: "none!important",
          }}
        />

        <div className="md:p-8">
          <div className="mb-4 flex items-center space-x-2">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-pink-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-white text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div>

            <h2 className="text-2xl font-semibold tracking-tight">
              Make a Donation
            </h2>
          </div>

          <p className="text-lg text-gray-500">
            Tracker Foundation works on community benefit projects around the
            Roseburg community.
          </p>
        </div>
      </div>
    </div>
  );
}
