import Image from "../components/Image/Image";

export function UFEDDonate({ tracker }) {
  if (!tracker) {
    return null;
  }

  return (
    <div className="col-span-8 mb-12">
      <div className="flex flex-col space-y-6 bg-white px-4 py-8 md:rounded-2xl lg:flex-row lg:space-x-6 lg:space-y-0">
        {/* <iframe
                    title="donate"
                    src="https://donorbox.org/embed/umpqua-live-fish-cam?default_interval=m"
                    name="donorbox"
                    className="border bg-slate-50 rounded-2xl"
                    allowpaymentrequest="allowpaymentrequest"
                    seamless="seamless"
                    frameborder="0"
                    scrolling="no"
                    width="100%"
                    height="1100px"
                    style={{
                      maxWidth: "500px",
                      minHeight: "500px",
                      minWidth: "310px",
                      maxHeight: "none!important"
                    }}
                  /> */}

        <div className="lg:px-8">
          <div className="mb-4 flex items-center space-x-2">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-rose-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-white text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div>

            <h2 className="text-xl font-bold text-slate-900">
              Make a donation
            </h2>
          </div>

          <p className="mb-6 font-serif text-slate-600">
            Your donations to Umpqua Fishery Enhancement Derby will help support
            fishery enhancement, restoration, and educational projects including
            this live Umpqua Fish camera. We extend our sincere appreciation to
            all who generously support the Derby year after year to make these
            projects possible. With our combined efforts, future generations
            will enjoy an ongoing healthy fishery in the Umpqua Basin.
          </p>

          <Image
            alt="header"
            sourceKey={tracker.headerImageKey}
            className="max-h-40 w-full rounded-md object-cover object-center md:max-h-72"
          />
        </div>

        <iframe
          src="https://donorbox.org/embed/umpqua-live-fish-cam"
          name="donorbox"
          title="donorbox"
          allowpaymentrequest="allowpaymentrequest"
          seamless="seamless"
          frameborder="0"
          scrolling="no"
          height="1200px"
          width="100%"
          style={{
            maxWidth: "500px",
            minHeight: "1200px",
            minWidth: "310px",
            maxHeight: "none!important",
          }}
        />
      </div>
    </div>
  );
}
