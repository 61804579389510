import React, { Component } from "react";
import { Link } from "react-router-dom";

// Custom
import Image from "../Image/Image";

class MobileNav extends Component {
  state = {
    notifications: false,
  }

  notifications = () => {
    this.setState({
      notifications: true
    });
  }

  render() {
    const {
      user,
      tracker,
      location,
      showMobileMenu,
      toggleMobileMenu,
      trackerSubdomain
    } = this.props;
    const { pathname } = location;
    const home = tracker ? (trackerSubdomain ? "/" : `/t/${tracker.name}`) : "/";
    const admin = tracker ? (trackerSubdomain ? "/admin" : `/t/${tracker.name}/admin`) + "/users" : "/";

    return (
      <div className="d-flex justify-content-around w-100">
        {/* Home */}
        <Link to={home} onClick={() => toggleMobileMenu(false)} className={(pathname === home && !showMobileMenu ? "active " : "") + "text-decoration-none d-flex flex-column align-items-center justify-content-center main-mobile-nav-item px-3 py-2"}>
          {pathname === home && !showMobileMenu ?
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 20 20" fill="currentColor"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" /></svg>
          :
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
          }

          <span className="extra-small font-weight-medium user-select-none">Home</span>
        </Link>

        {/* Admin */}
        {user && user.admin && tracker &&
          <Link to={admin} onClick={() => toggleMobileMenu(false)} className={(pathname === admin && !showMobileMenu ? "active " : "") + "position-relative text-decoration-none d-flex flex-column align-items-center justify-content-center main-mobile-nav-item px-3 py-2"}>
            {pathname === admin && !showMobileMenu ?
              <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 20 20" fill="currentColor"><path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" /></svg>
            :
              <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>
            }

            <span className="extra-small font-weight-medium user-select-none text-decoration-none">Admin</span>

            {tracker && user && user.adminData && user.admin && (user.adminData.users > 0 || user.adminData.posts > 0) &&
              <span className="position-absolute text-white extra-small font-weight-extrabold d-flex align-items-center justify-content-center top-0 right-0 rounded-circle main-mobile-nav-alert">
                {user.adminData.users + user.adminData.posts}
              </span>
            }
          </Link>
        }

        {/* Profile */}
        {user &&
          <Link to="/profile?tab=settings" onClick={() => toggleMobileMenu(false)} className={(pathname === "/profile" && !showMobileMenu ? "active " : "") + "text-decoration-none d-flex flex-column align-items-center justify-content-center main-mobile-nav-item px-3 py-2"}>
            <div className="d-flex align-items-center justify-content-center" style={{
              width: "28px",
              height: "28px",
            }}>
              <Image
                resize={{
                  width: 44,
                  height: 44,
                }}
                width={22}
                height={22}
                sourceKey={user.profilePhotoKey}
                alt=""
                className={(pathname === "/profile" && !showMobileMenu ? "shadow-blue " : "") + "rounded-circle"}
              />
            </div>

            <span className="extra-small font-weight-medium user-select-none">Profile</span>
          </Link>
        }

        {/* Menu */}
        <div onClick={toggleMobileMenu} className={(showMobileMenu ? "active " : "") + "d-flex flex-column align-items-center justify-content-center cursor-pointer main-mobile-nav-item px-3 py-2 position-relative"}>
          <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 6h16M4 12h16M4 18h16" /></svg>
          <span className="extra-small font-weight-medium user-select-none text-decoration-none">Menu</span>
        </div>
      </div>
    );
  }
}

export default MobileNav;
