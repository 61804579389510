import React, { Component } from "react";

// Custom
import ConnectionError from "./Pages/Error/ConnectionError";

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error, info) {
    this.setState({
      error: error,
      errorInfo: info
    });

    console.error(error, info);
  }

  render() {
    const { error } = this.state;

    if (error && error.name === "ChunkLoadError") {
      return <ConnectionError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
