import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import axios from "axios";
import querystring from "query-string";
import { IconContext } from "react-icons";
import * as nearAPI from "near-api-js";

// Requests
import { getUser, getTracker, getUserTrackers } from "../requests";

// Bootstrap
import Alert from "react-bootstrap/Alert";

// Custom
import loader from "../loader";
import { NearContext } from "../context";
import ErrorBoundary from "./ErrorBoundary";
import Navigation from "./Navigation/Navigation";
import ConnectionError from "./Pages/Error/ConnectionError";
import { UFEDDonate } from "../pages/ufed-donate";
import { UFEDAbout } from "../pages/ufed-about";
import { FWWRDonate } from "../pages/fwwr-donate";
import { FWWRAbout } from "../pages/fwwr-about";
import { TrackerFoundationDonate } from "../pages/trackerfoundation-donate";

// Custom lazy loaded components
const lazyLoader = (component) => lazy(() => loader(component));
const Jobs = lazyLoader(() => import("./Pages/Jobs/Jobs"));
const Admin = lazyLoader(() => import("./Pages/Admin/Admin"));
const Login = lazyLoader(() => import("./Pages/Login/Login"));
const JobPost = lazyLoader(() => import("./Pages/Jobs/JobPost"));
const Signup = lazyLoader(() => import("./Pages/Signup/Signup"));
const Donate = lazyLoader(() => import("./Pages/Donate/Donate"));
const Profile = lazyLoader(() => import("./Pages/Profile/Profile"));
const NotFound = lazyLoader(() => import("./Pages/Error/NotFound"));
const Coupon = lazyLoader(() => import("./Pages/Coupons/Coupon"));
const Coupons = lazyLoader(() => import("./Pages/Coupons/Coupons"));
const Takeout = lazyLoader(() => import("./Pages/Business/Takeout"));
const Settings = lazyLoader(() => import("./Pages/Settings/Settings"));
const NewPostEditor = lazyLoader(() => import("./Pages/NewPost/Editor"));
const Advertise = lazyLoader(() => import("./Pages/Advertise/Advertise"));
const MonitoringTilt = lazyLoader(() => import("./Pages/Monitoring/Tilt"));
const Newsletter = lazyLoader(() => import("./Pages/Newsletter/Newsletter"));
const QrCodeReader = lazyLoader(() => import("./Pages/QrCode/QrCodeReader"));
const SinglePost = lazyLoader(() => import("./Pages/SinglePost/SinglePost"));
const JamesFinlay = lazyLoader(() => import("./Pages/JamesFinlay/JamesFinlay"));
const Sponsorship = lazyLoader(() => import("./Pages/Sponsorship/Sponsorship"));
const MainLanding = lazyLoader(() => import("./Pages/MainLanding/MainLanding"));
const ResetPassword = lazyLoader(() =>
  import("./Pages/Settings/ResetPassword")
);
const MonitoringWebcams = lazyLoader(() =>
  import("./Pages/Monitoring/Webcams")
);
const JobBoardCustomer = lazyLoader(() =>
  import("./Pages/Jobs/JobBoardCustomer")
);
const IN_DEV_GROUP = lazyLoader(() =>
  import("./Pages/_InDevGroup/TrackerLanding")
);
const FestivalOfTrees = lazyLoader(() =>
  import("./Pages/Business/FestivalOfTrees")
);
const MonitoringTwoYearGPS = lazyLoader(() =>
  import("./Pages/Monitoring/2YearGPS")
);
const AudioPostDemo = lazyLoader(() =>
  import("./Pages/AudioPostDemo/AudioPostDemo")
);
const MonitoringTenYearGPS = lazyLoader(() =>
  import("./Pages/Monitoring/10YearGPS")
);
const PrivacyPolicy = lazyLoader(() =>
  import("./Pages/PrivacyPolicy/PrivacyPolicy")
);
const SponsorPayment = lazyLoader(() =>
  import("./Pages/Sponsorship/SponsorPayment")
);
const AdvertisePayment = lazyLoader(() =>
  import("./Pages/Advertise/AdvertisePayment")
);
const FestivalOfTrees21 = lazyLoader(() =>
  import("./Pages/Business/FestivalOfTrees21")
);
const FestivalOfTrees22 = lazyLoader(() =>
  import("./Pages/Business/FestivalOfTrees22")
);
const TrackerLanding = lazyLoader(() =>
  import("./Pages/TrackerLanding/TrackerLanding")
);

const { connect, keyStores, WalletConnection } = nearAPI;

class App extends Component {
  state = {
    user: null,
    near: null,
    error: null,
    loading: true,
    tracker: null,
    nearWallet: null,
    userTrackers: [],
    customDomain: null,
  };

  async connectNear() {
    // const config = {
    //   networkId: "testnet",
    //   keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    //   nodeUrl: "https://rpc.testnet.near.org",
    //   walletUrl: "https://wallet.testnet.near.org",
    //   helperUrl: "https://helper.testnet.near.org",
    //   explorerUrl: "https://explorer.testnet.near.org",
    // };
    const config = {
      networkId: "mainnet",
      keyStore: new keyStores.BrowserLocalStorageKeyStore(),
      nodeUrl: "https://rpc.mainnet.near.org",
      walletUrl: "https://wallet.mainnet.near.org",
      helperUrl: "https://helper.mainnet.near.org",
      explorerUrl: "https://explorer.mainnet.near.org",
    };

    const near = await connect(config);
    const nearWallet = new WalletConnection(near);

    this.setState({
      near,
      nearWallet,
    });
  }

  nearLogin = () => {
    const { nearWallet } = this.state;

    const callbackURL = window.location.href;
    let successURL = callbackURL;
    let failURL = callbackURL;

    if (window.location.search) {
      successURL += "&near_success=true";
      failURL += "&near_success=false";
    } else {
      successURL += "?near_success=true";
      failURL += "?near_success=false";
    }

    // nearWallet.requestSignIn(
    //   "tracker.testnet",
    //   "Tracker",
    //   successURL,
    //   failURL,
    // );

    nearWallet.requestSignIn("tracker.near", "Tracker", successURL, failURL);
  };

  nearLogout = () => {
    const { nearWallet } = this.state;

    nearWallet.signOut();
    this.setState({ nearWallet });
  };

  componentDidMount() {
    this.connectNear();

    // Remove navigation prompt when leaving page
    window.onbeforeunload = null;

    const { groupName, customDomain } = this.groupName();

    if (groupName) {
      this.getTrackerData(groupName, customDomain);
    } else {
      this.setState({ loading: true });

      axios
        .all([getUser(), getUserTrackers()])
        .then(
          axios.spread((user, userTrackers) => {
            this.setState({
              loading: false,
              user: user.data,
              userTrackers: userTrackers ? userTrackers.data : [],
            });
          })
        )
        .catch((err) => {
          this.setState({
            loading: false,
            error: "There was a network connection error",
          });

          console.error(err);
        });
    }
  }

  // Return a value if the user is on a group custom domain and a condition is met
  ifCustomDomain = ({ condition, yes, no }) => {
    const { customDomain } = this.state;

    if (customDomain && condition === true) {
      return yes;
    }

    return no;
  };

  // Get the name of the current group
  groupName = () => {
    const { host, pathname } = window.location;

    // List of items that aren't included in any group custom domains
    const nonCustomHosts = [
      ":3000",
      ":3001",
      "www.jointracker.com",
      "herokuapp.com",
    ];
    // Determine if the current host is a custom group domain
    const customDomain = !nonCustomHosts.find((item) => host.includes(item));
    // If the current host isn't a custom domain then check if a group name is present at "/t/[name]"
    const groupPath = pathname.replace("/t/", "");
    // Get the group name
    const groupName = customDomain ? host : groupPath;

    if (customDomain) {
      return {
        groupName,
        customDomain: true,
      };
    } else if (
      groupName &&
      groupName !== "/" &&
      pathname.indexOf("/t/") !== -1
    ) {
      // If URL is "/t/[tracker]/[some path]"
      if (groupName.indexOf("/") !== -1) {
        return {
          groupName: groupName.substring(0, groupName.indexOf("/")),
          customDomain: null,
        };
      } else {
        return {
          groupName,
          customDomain: null,
        };
      }
    } else {
      return {
        groupName: null,
        customDomain: null,
      };
    }
  };

  getTrackerData(tracker, customDomain) {
    this.setState({
      loading: true,
      customDomain,
    });

    const requests = [getUser(tracker), getTracker(tracker)];

    if (!customDomain) {
      requests.push(getUserTrackers());
    }

    axios
      .all(requests)
      .then(
        axios.spread((user, tracker, userTrackers = null) => {
          // If there is no group
          if (customDomain && !tracker.data) {
            this.setState({
              customDomain: false,
            });
          }

          this.setState({
            loading: false,
            user: user.data,
            tracker: tracker.data,
            userTrackers: userTrackers ? userTrackers.data : [],
          });
        })
      )
      .catch((err) => {
        this.setState({
          loading: false,
          error: "There was a network connection error",
        });

        console.error(err);
      });
  }

  render() {
    const {
      user,
      near,
      error,
      tracker,
      loading,
      nearWallet,
      userTrackers,
      customDomain,
    } = this.state;

    if (loading) {
      return null;
    } else if (error) {
      return <ConnectionError />;
    }

    if (["jobs.roseburgtracker.com"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider
                value={{
                  near,
                  nearWallet,
                }}
              >
                <IconContext.Provider value={{ className: "react-icon" }}>
                  {/* Roseburg Tracker job board */}
                  <Route exact path="/" component={Jobs} />
                  {/* Roseburg Tracker job board purchase success */}
                  <Route
                    exact
                    path="/jobs/customer-success"
                    component={
                      <JobBoardCustomer trackerSubdomain={customDomain} />
                    }
                  />
                  {/* Roseburg Tracker job board */}
                  <Route
                    exact
                    path="/:id"
                    component={({ match }) => {
                      return <JobPost match={match} />;
                    }}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    if (["sales.roseburgtracker.com"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider
                value={{
                  near,
                  nearWallet,
                }}
              >
                <IconContext.Provider value={{ className: "react-icon" }}>
                  <Route
                    exact
                    path="/"
                    component={() => (
                      <Advertise
                        tracker="roseburg"
                        trackerSubdomain={customDomain}
                      />
                    )}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    if (["coupons.roseburgtracker.com"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider value={{ near, nearWallet }}>
                <IconContext.Provider value={{ className: "react-icon" }}>
                  {/* Coupons */}
                  <Route
                    exact
                    path="/"
                    component={() => <Coupons tracker={tracker} />}
                  />

                  {/* Individual Coupons */}
                  <Route
                    exact
                    path="/coupons/:couponId"
                    component={({ match }) => {
                      return (
                        <Coupon
                          tracker={tracker}
                          customDomain={customDomain}
                          id={match.params.couponId}
                        />
                      );
                    }}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    if (["monitoring.hawaiitracker.com"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider value={{ near, nearWallet }}>
                <IconContext.Provider value={{ className: "react-icon" }}>
                  {/* Monitoring */}
                  <Route
                    exact
                    path={["/", "/webcams"]}
                    component={() => (
                      <MonitoringWebcams trackerSubdomain={customDomain} />
                    )}
                  />

                  {/* Monitoring */}
                  <Route
                    exact
                    path="/tilt"
                    component={() => (
                      <MonitoringTilt trackerSubdomain={customDomain} />
                    )}
                  />

                  {/* Monitoring */}
                  <Route
                    exact
                    path="/2-year-gps"
                    component={() => (
                      <MonitoringTwoYearGPS trackerSubdomain={customDomain} />
                    )}
                  />

                  {/* Monitoring */}
                  <Route
                    exact
                    path="/10-year-gps"
                    component={() => (
                      <MonitoringTenYearGPS trackerSubdomain={customDomain} />
                    )}
                  />

                  <Route
                    exact
                    path="/support"
                    component={() => <Donate trackerName="hawaiitracker" />}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    if (["donate.umpquafish.com"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider value={{ near, nearWallet }}>
                <IconContext.Provider value={{ className: "react-icon" }}>
                  <Route
                    exact
                    path={"/"}
                    component={() => <UFEDDonate tracker={tracker} />}
                  />
                  <Route
                    exact
                    path={"/about"}
                    component={() => <UFEDAbout tracker={tracker} />}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    if (["donate.fwwr.org"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider value={{ near, nearWallet }}>
                <IconContext.Provider value={{ className: "react-icon" }}>
                  <Route
                    exact
                    path={"/"}
                    component={() => <FWWRDonate tracker={tracker} />}
                  />
                  <Route
                    exact
                    path={"/about"}
                    component={() => <FWWRAbout tracker={tracker} />}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    if (["donate.trackerfoundation.com"].includes(window.location.hostname)) {
      return (
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <NearContext.Provider value={{ near, nearWallet }}>
                <IconContext.Provider value={{ className: "react-icon" }}>
                  <Route
                    exact
                    path={"/"}
                    component={() => (
                      <TrackerFoundationDonate />
                    )}
                  />
                </IconContext.Provider>
              </NearContext.Provider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      );
    }

    return (
      <Router>
        <ErrorBoundary>
          <Suspense fallback={null}>
            <NearContext.Provider
              value={{
                near,
                nearWallet,
              }}
            >
              <IconContext.Provider value={{ className: "react-icon" }}>
                {/* Navigation */}
                <Route
                  path="*"
                  component={({ location }) => {
                    // Show a custom nav on landing page
                    if (
                      !customDomain &&
                      ((!user && location.pathname === "/") ||
                        ["/features"].includes(location.pathname))
                    ) {
                      return null;
                    }

                    if (
                      location.pathname.includes("/admin") ||
                      location.pathname.includes("/publish")
                    ) {
                      return null;
                    }

                    return (
                      <>
                        <Navigation
                          user={user}
                          tracker={tracker}
                          location={location}
                          trackerSubdomain={customDomain}
                          ifTrackerSubdomain={this.ifCustomDomain}
                        />

                        {user && user.accountStatus === "inactive" && (
                          <Alert
                            variant="warning"
                            className="medium text-center mb-0"
                          >
                            Your account has been disabled. You can't interact
                            on {tracker ? tracker.displayName : "Tracker"}{" "}
                            anymore.
                          </Alert>
                        )}
                      </>
                    );
                  }}
                />

                <Switch>
                  {/* Main Page */}
                  <Route
                    exact
                    path="/"
                    component={() => {
                      if (customDomain) {
                        if (
                          [
                            "fwwr",
                            "sunrise-tree-service",
                            "umpquafish",
                            "tracker-foundation",
                          ].includes(tracker.name)
                        ) {
                          return (
                            <IN_DEV_GROUP
                              user={user}
                              tracker={tracker}
                              combinedFeed={false}
                              userTrackers={userTrackers}
                              trackerSubdomain={customDomain}
                            />
                          );
                        } else {
                          return (
                            <TrackerLanding
                              user={user}
                              tracker={tracker}
                              combinedFeed={false}
                              userTrackers={userTrackers}
                              trackerSubdomain={customDomain}
                            />
                          );
                        }
                      }

                      if (user && userTrackers[0]?.name) {
                        window.location.href = `/t/${userTrackers[1].name}`;
                      } else {
                        return <MainLanding />;
                      }
                    }}
                  />

                  {/* Tracker Landing */}
                  {/* {!customDomain && <Route exact path="/t/:tracker" component={() =>
                    <TrackerLanding
                      user={user}
                      tracker={tracker}
                      userTrackers={userTrackers}
                    />
                  } />} */}

                  {!customDomain && (
                    <Route
                      exact
                      path={[
                        "/t/fwwr",
                        "/t/sunrise-tree-service",
                        "/t/umpquafish",
                        "/t/tracker-foundation",
                      ]}
                      component={() => {
                        return (
                          <IN_DEV_GROUP
                            page="main"
                            user={user}
                            tracker={tracker}
                            userTrackers={userTrackers}
                            trackerSubdomain={customDomain}
                          />
                        );
                      }}
                    />
                  )}

                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/publish",
                      no: [
                        "/t/fwwr/publish",
                        "/t/sunrise-tree-service/publish",
                        "/t/umpquafish/publish",
                        "/t/tracker-foundation/publish",
                      ],
                    })}
                    component={({ history }) => {
                      const { search } = history.location;
                      const { p } = querystring.parse(search);

                      if (tracker) {
                        if (
                          !user ||
                          !user.member ||
                          user.accountStatus === "inactive"
                        ) {
                          return <NotFound />;
                        } else {
                          return (
                            <NewPostEditor
                              page={p}
                              user={user}
                              tracker={tracker}
                              customDomain={customDomain}
                            />
                          );
                        }
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/admin",
                      no: [
                        "/t/fwwr/admin",
                        "/t/sunrise-tree-service/admin",
                        "/t/umpquafish/admin",
                        "/t/tracker-foundation/admin",
                      ],
                    })}
                    component={({ match }) => {
                      if (user && (user.admin || user.superAdmin) && tracker) {
                        return (
                          <Admin
                            user={user}
                            match={match}
                            tracker={tracker}
                            customDomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition:
                        tracker &&
                        ["fwwr", "umpquafish", "tracker-foundation"].includes(
                          tracker.name
                        ),
                      yes: ["/about", "/newsletter", "/donate"],
                      no: [
                        "/t/fwwr/:tab",
                        "/t/umpquafish/:tab",
                        "/t/tracker-foundation/:tab",
                      ],
                    })}
                    component={({ match }) => {
                      let page = null;

                      if (window.location.pathname === "/about") {
                        page = "about";
                      } else if (window.location.pathname === "/newsletter") {
                        page = "newsletter";
                      } else if (window.location.pathname === "/donate") {
                        page = "donate";
                      }

                      return (
                        <IN_DEV_GROUP
                          user={user}
                          tracker={tracker}
                          page={page || match.params.tab}
                          userTrackers={userTrackers}
                          trackerSubdomain={customDomain}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition:
                        tracker &&
                        ["sunrise-tree-service"].includes(tracker.name),
                      yes: [
                        "/about",
                        "/newsletter",
                        "/donate",
                        "/tree-trimming",
                        "/tree-removal",
                        "/stump-grinding",
                        "/pruning",
                      ],
                      no: ["/t/sunrise-tree-service/:tab"],
                    })}
                    component={({ match }) => {
                      let page = null;

                      if (window.location.pathname === "/tree-trimming") {
                        page = "tree-trimming";
                      } else if (window.location.pathname === "/tree-removal") {
                        page = "tree-removal";
                      } else if (
                        window.location.pathname === "/stump-grinding"
                      ) {
                        page = "stump-grinding";
                      } else if (window.location.pathname === "/pruning") {
                        page = "pruning";
                      }

                      return (
                        <IN_DEV_GROUP
                          user={user}
                          tracker={tracker}
                          page={page || match.params.tab}
                          userTrackers={userTrackers}
                          trackerSubdomain={customDomain}
                        />
                      );
                    }}
                  />

                  {/* Tracker Landing */}
                  {!customDomain && (
                    <Route
                      exact
                      path="/t/:tracker"
                      component={() => {
                        return (
                          <TrackerLanding
                            user={user}
                            tracker={tracker}
                            userTrackers={userTrackers}
                            trackerSubdomain={customDomain}
                          />
                        );
                      }}
                    />
                  )}

                  {/* Donate */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition:
                        tracker &&
                        ["roseburg", "hawaii"].includes(tracker.name),
                      yes: "/support",
                      no: "/t/:tracker/support",
                    })}
                    component={() => {
                      if (
                        tracker &&
                        ["roseburg", "hawaii"].includes(tracker.name)
                      ) {
                        return <Donate trackerName={tracker.name} />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Redirect to "/posts/:postId" on old post links */}
                  <Route
                    exact
                    path="/posts/:postId/view"
                    component={({ match }) =>
                      (window.location.href = `/posts/${match.params.postId}`)
                    }
                  />

                  {/* Single Post */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/posts/:postId",
                      no: "/t/:tracker/posts/:postId",
                    })}
                    component={({ match }) => {
                      const { postId } = match.params;

                      if (postId && tracker) {
                        return (
                          <SinglePost
                            postId={postId}
                            user={user}
                            tracker={tracker}
                            userTrackers={userTrackers}
                            trackerSubdomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Landing */}
                  <Route exact path="/features" component={MainLanding} />

                  {/* Login */}
                  <Route
                    exact
                    path="/login"
                    component={() => {
                      if (!user) {
                        return <Login />;
                      } else {
                        window.location.href = "/";
                      }
                    }}
                  />

                  {/* Sign up */}
                  <Route
                    exact
                    path="/signup"
                    component={() => {
                      if (!user) {
                        return <Signup />;
                      }

                      window.location.href = "/";
                    }}
                  />

                  {/* Profile */}
                  <Route
                    exact
                    path="/profile"
                    component={({ history }) => {
                      const { search } = history.location;
                      const { tab } = querystring.parse(search);
                      let activeTab = "posts";

                      if (["posts", "communities", "settings"].includes(tab)) {
                        activeTab = tab;
                      }

                      if (!user) {
                        return <NotFound />;
                      }

                      return (
                        <Profile
                          user={user}
                          near={near}
                          tab={activeTab}
                          history={history}
                          tracker={tracker}
                          nearWallet={nearWallet}
                          nearLogin={this.nearLogin}
                          nearLogout={this.nearLogout}
                          trackerSubdomain={customDomain}
                        />
                      );
                    }}
                  />

                  {/* Group Newsletter */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/publish",
                      no: "/t/:tracker/publish",
                    })}
                    component={({ history }) => {
                      const { search } = history.location;
                      const { p } = querystring.parse(search);

                      if (tracker) {
                        if (
                          !user ||
                          !user.member ||
                          user.accountStatus === "inactive"
                        ) {
                          return <NotFound />;
                        } else {
                          return (
                            <NewPostEditor
                              page={p}
                              user={user}
                              tracker={tracker}
                              customDomain={customDomain}
                            />
                          );
                        }
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Group Newsletter */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/newsletter",
                      no: "/t/:tracker/newsletter",
                    })}
                    component={() => {
                      if (tracker && tracker.mailingListAddress) {
                        if (tracker.private && (!user || !user.member)) {
                          return <NotFound />;
                        } else {
                          return <Newsletter tracker={tracker} />;
                        }
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Group Verify Email */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/newsletter/verify/:token",
                      no: "/t/:tracker/newsletter/verify/:token",
                    })}
                    component={({ match }) => {
                      if (tracker && tracker.mailingListAddress) {
                        if (tracker.private && (!user || !user.member)) {
                          return <NotFound />;
                        } else {
                          return (
                            <Newsletter
                              tracker={tracker}
                              token={match.params.token}
                              customDomain={customDomain}
                            />
                          );
                        }
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Platform Admin */}
                  {!tracker && (
                    <Route
                      path="/admin"
                      component={({ match }) => {
                        if (user && user.role === "superadmin" && !tracker) {
                          return (
                            <Admin
                              user={user}
                              match={match}
                              superAdmin={true}
                            />
                          );
                        } else {
                          return <NotFound />;
                        }
                      }}
                    />
                  )}

                  {/* Group Admin */}
                  <Route
                    path={this.ifCustomDomain({
                      condition: true,
                      yes: "/admin",
                      no: "/t/:tracker/admin",
                    })}
                    component={({ match }) => {
                      if (user && user.admin && tracker) {
                        return (
                          <Admin
                            user={user}
                            match={match}
                            tracker={tracker}
                            customDomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Settings */}
                  <Route
                    exact
                    path="/settings"
                    component={({ history }) => {
                      const { search } = history.location;
                      const { tab } = querystring.parse(search);
                      let activeTab = "change-password";

                      if (tab) {
                        activeTab = tab;
                      }

                      if (user) {
                        return (
                          <Settings history={history} activeTab={activeTab} />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Reset Password */}
                  <Route
                    exact
                    path="/reset-password"
                    component={() => {
                      if (!user) {
                        return <ResetPassword />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Qr Code Reader */}
                  <Route exact path="/qrcode" component={QrCodeReader} />

                  {/* Privacy */}
                  <Route exact path="/privacy" component={PrivacyPolicy} />

                  {/* James Finlay Memorial */}
                  <Route exact path="/jamesfinlay" component={JamesFinlay} />

                  {/* Family Tracker Demo */}
                  <Route
                    exact
                    path="/audio-post-demo"
                    component={AudioPostDemo}
                  />

                  {/* Coupons */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/coupons",
                      no: "/t/roseburg/coupons",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return <Coupons tracker={tracker} />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Individual Coupons */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/coupons/:couponId",
                      no: "/t/roseburg/coupons/:couponId",
                    })}
                    component={({ match }) => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <Coupon
                            tracker={tracker}
                            customDomain={customDomain}
                            id={match.params.couponId}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Sponsorship */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/sponsorship",
                      no: "/t/roseburg/sponsorship",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return <Sponsorship trackerSubdomain={customDomain} />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Sponsorship Checkout */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/sponsor/package/:packageId",
                      no: "/t/roseburg/sponsor/package/:packageId",
                    })}
                    component={({ match }) => {
                      const packageId = parseInt(match.params.packageId);

                      if (
                        tracker &&
                        tracker.name === "roseburg" &&
                        packageId > 0 &&
                        packageId < 4
                      ) {
                        // Test Key: "pk_test_7KFRFC2yLCfOztWqBiUd4aLH00esvH7CiJ"
                        return (
                          <StripeProvider
                            apiKey={
                              "pk_live_GErzMC2kfvvKoYjZQytGTkKL00Y89rRsYZ"
                            }
                          >
                            <Elements>
                              <SponsorPayment
                                tracker={tracker}
                                packageId={packageId}
                              />
                            </Elements>
                          </StripeProvider>
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Roseburg Tracker job board */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/jobs",
                      no: "/t/roseburg/jobs",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return <Jobs />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Roseburg Tracker job board purchase success */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/jobs/customer-success",
                      no: "/t/roseburg/jobs/customer-success",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <JobBoardCustomer trackerSubdomain={customDomain} />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Roseburg Tracker job board */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/jobs/:id",
                      no: "/t/roseburg/jobs/:id",
                    })}
                    component={({ match }) => {
                      if (tracker && tracker.name === "roseburg") {
                        return <JobPost match={match} />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Festival of Trees */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/festivaloftrees",
                      no: "/t/roseburg/festivaloftrees",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <FestivalOfTrees trackerSubdomain={customDomain} />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Festival of Trees */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/festivaloftrees/tree-:tree",
                      no: "/t/roseburg/festivaloftrees/tree-:tree",
                    })}
                    component={({ match }) => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <FestivalOfTrees
                            match={match}
                            trackerSubdomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Festival of Trees 2021 */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/festivaloftrees-2021",
                      no: "/t/roseburg/festivaloftrees-2021",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <FestivalOfTrees21 trackerSubdomain={customDomain} />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Festival of Trees 2021 */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/festivaloftrees-2021/tree-:tree",
                      no: "/t/roseburg/festivaloftrees-2021/tree-:tree",
                    })}
                    component={({ match }) => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <FestivalOfTrees21
                            match={match}
                            trackerSubdomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Festival of Trees 2022 */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/festivaloftrees-2022",
                      no: "/t/roseburg/festivaloftrees-2022",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <FestivalOfTrees22 trackerSubdomain={customDomain} />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Festival of Trees 2022 */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/festivaloftrees-2022/tree-:tree",
                      no: "/t/roseburg/festivaloftrees-2022/tree-:tree",
                    })}
                    component={({ match }) => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <FestivalOfTrees22
                            match={match}
                            trackerSubdomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Support Roseburg Restaurants */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/takeout",
                      no: "/t/roseburg/takeout",
                    })}
                    component={({ history }) => {
                      if (tracker && tracker.name === "roseburg") {
                        return <Takeout history={history} />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Roseburg Tracker Advertise */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/advertise",
                      no: "/t/roseburg/advertise",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "roseburg") {
                        return (
                          <Advertise
                            tracker="roseburg"
                            trackerSubdomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Hawaii Tracker Advertise */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "hawaii",
                      yes: "/advertise",
                      no: "/t/hawaii/advertise",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "hawaii") {
                        return (
                          <Advertise
                            tracker="hawaii"
                            trackerSubdomain={customDomain}
                          />
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Roseburg Advertise Checkout */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "roseburg",
                      yes: "/advertise/package/:packageId",
                      no: "/t/roseburg/advertise/package/:packageId",
                    })}
                    component={({ match }) => {
                      const packageId = parseInt(match.params.packageId);

                      if (
                        tracker &&
                        tracker.name === "roseburg" &&
                        packageId > 0 &&
                        packageId < 4
                      ) {
                        // Test Key: "pk_test_7KFRFC2yLCfOztWqBiUd4aLH00esvH7CiJ"
                        // Live Key: "pk_live_GErzMC2kfvvKoYjZQytGTkKL00Y89rRsYZ"
                        return (
                          <StripeProvider
                            apiKey={
                              "pk_live_GErzMC2kfvvKoYjZQytGTkKL00Y89rRsYZ"
                            }
                          >
                            <Elements>
                              <AdvertisePayment
                                tracker={tracker}
                                packageId={packageId}
                              />
                            </Elements>
                          </StripeProvider>
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Hawaii Advertise Checkout */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "hawaii",
                      yes: "/advertise/package/:packageId",
                      no: "/t/hawaii/advertise/package/:packageId",
                    })}
                    component={({ match }) => {
                      const packageId = parseInt(match.params.packageId);

                      if (
                        tracker &&
                        tracker.name === "hawaii" &&
                        packageId > 0 &&
                        packageId < 4
                      ) {
                        // Test Key: "pk_test_6MmJ9rY6ipNnXVXTudTVyP8q"
                        // Live Key: "pk_live_QNUEvbLdDGBNQKMWQkp7pa7Y"
                        return (
                          <StripeProvider
                            apiKey={"pk_live_QNUEvbLdDGBNQKMWQkp7pa7Y"}
                          >
                            <Elements>
                              <AdvertisePayment
                                tracker={tracker}
                                packageId={packageId}
                              />
                            </Elements>
                          </StripeProvider>
                        );
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Hawaii Tracker Webcams */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "hawaii",
                      yes: "/monitoring/webcams",
                      no: "/t/hawaii/monitoring/webcams",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "hawaii") {
                        return <MonitoringWebcams />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Hawaii Tracker Tilt */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "hawaii",
                      yes: "/monitoring/tilt",
                      no: "/t/hawaii/monitoring/tilt",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "hawaii") {
                        return <MonitoringTilt />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Hawaii Tracker Two Year GPS */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "hawaii",
                      yes: "/monitoring/2-year-gps",
                      no: "/t/hawaii/monitoring/2-year-gps",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "hawaii") {
                        return <MonitoringTwoYearGPS />;
                      }
                    }}
                  />

                  {/* Hawaii Tracker Ten Year GPS */}
                  <Route
                    exact
                    path={this.ifCustomDomain({
                      condition: tracker && tracker.name === "hawaii",
                      yes: "/monitoring/10-year-gps",
                      no: "/t/hawaii/monitoring/10-year-gps",
                    })}
                    component={() => {
                      if (tracker && tracker.name === "hawaii") {
                        return <MonitoringTenYearGPS />;
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />

                  {/* Not Found */}
                  <Route component={NotFound} />
                </Switch>
              </IconContext.Provider>
            </NearContext.Provider>
          </Suspense>
        </ErrorBoundary>
      </Router>
    );
  }
}

export default App;
