import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import Image from "../Image/Image";
import Swipe from "react-easy-swipe";

class MobileNavFlyout extends Component {
  onSwipeMove = position => {
    const {
      showMobileMenu,
      toggleMobileMenu
    } = this.props;

    if (showMobileMenu && position.y > 50) {
      toggleMobileMenu(false);
    }
  }

  render() {
    const {
      user,
      tracker,
      showMobileMenu,
      trackerSubdomain,
      toggleMobileMenu,
    } = this.props;

    return (
      <>
        <Swipe onSwipeMove={showMobileMenu ? this.onSwipeMove : () => {}} className={(showMobileMenu ? "show" : "hidden") + " main-ui-nav-overlay-bg"} />

        <div className={(showMobileMenu ? "show" : "hidden") + " d-md-none position-fixed w-100 main-ui-nav-overlay-container"}>
          <div className="main-ui-nav-overlay-bar bg-white card-shadow rounded-pill"></div>

          <Swipe
            onSwipeMove={this.onSwipeMove}
            className="position-fixed main-ui-nav-overlay w-100 pb-5"
          >
            <div
              className="main-ui-nav-overlay-header border-bottom w-100 d-flex justify-content-between px-3 pt-4 pb-3"
            >
              <h5 className="font-weight-extrabold">
                {tracker ? tracker.displayName : "Menu"}
              </h5>

              <svg onClick={() => toggleMobileMenu(false)} xmlns="http://www.w3.org/2000/svg" className="cursor-pointer text-secondary img-icon-xxs" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
            </div>

            <div className="pt-3 pb-5 main-ui-nav-overlay-content d-flex flex-column" onClick={() => toggleMobileMenu(false)}>
              {user &&
                <Link to="/profile?tab=settings" className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item d-flex align-items-center">
                  <Image
                    resize={{
                      width: 48,
                      height: 48,
                    }}
                    sourceKey={user.profilePhotoKey}
                    alt=""
                    className="img-icon-xxs mr-3 rounded-circle"
                  />

                  <p className="mb-0 font-weight-medium text-dark">
                    {user.firstname} {user.lastname}
                  </p>
                </Link>
              }

              {tracker && user && user.admin &&
                <Link to={(trackerSubdomain ? "" : `/t/${tracker.name}`) + `/admin/users`} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Admin
                  </p>
                </Link>
              }

              {tracker && user && user.adminData && user.admin && (user.adminData.users > 0 || user.adminData.posts > 0) &&
                <div className="position-relative px-3 py-2 mb-2 main-ui-nav-overlay-item">
                  <Menu>
                    <Menu.Button className="border-0 bg-transparent text-muted transition-color p-0 position-relative d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
                      <span className="btn-lava position-absolute top-0 left-0 rounded-circle" style={{
                        width: "10px",
                        height: "10px",
                        top: "0",
                      }} />

                      <p className="mb-0 font-weight-medium text-dark">
                        Notifications
                      </p>
                    </Menu.Button>

                    <Menu.Items className="mx-3 headless-ui-dropdown border w-56 d-flex flex-column position-absolute left-0 mt-2 bg-white rounded">
                      <Menu.Item className="px-3 py-2 my-2 d-block font-weight-medium medium text-dark">
                        <span>
                          Notifications
                        </span>
                      </Menu.Item>

                      <hr className="w-100 mt-0 mb-2" />

                      <div className="pb-2">
                        {user.adminData.users > 0 &&
                          <Menu.Item className="px-3 py-2 text-decoration-none d-block text-dark medium font-weight-medium d-flex justify-content-between">
                            <Link to={trackerSubdomain ? "/admin/member-requests" : `/t/${tracker.name}/admin/member-requests`}>
                              <span>
                                Group Requests
                              </span>

                              <span className="badge badge-primary">
                                {user.adminData.users}
                              </span>
                            </Link>
                          </Menu.Item>
                        }

                        {user.adminData.posts > 0 &&
                          <Menu.Item className="px-3 py-2 text-decoration-none d-block text-dark medium font-weight-medium d-flex justify-content-between">
                            <Link to={trackerSubdomain ? "/admin/pending-posts" : `/t/${tracker.name}/admin/pending-posts`}>
                              <span>
                                Pending Posts
                              </span>

                              <span className="badge badge-primary">
                                {user.adminData.posts}
                              </span>
                            </Link>
                          </Menu.Item>
                        }
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>
              }

              {!user && tracker?.name !== "umpquafish" &&
                <Link to={`/signup${tracker && !trackerSubdomain ? "?t=" + tracker.name : ""}`} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Create Account
                  </p>
                </Link>
              }

              {!user &&
                <Link to={`/login${tracker && !trackerSubdomain ? "?t=" + tracker.name : ""}`} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Sign In
                  </p>
                </Link>
              }

              {tracker && tracker.name === "roseburg" &&
                <Link to={trackerSubdomain ? "/jobs" : "/t/roseburg/jobs"} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Local Jobs
                  </p>
                </Link>
              }

              {tracker && tracker.name === "roseburg" &&
                <Link to={trackerSubdomain ? "/coupons" : "/t/roseburg/coupons"} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}><path strokeLinecap="round" strokeLinejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Local Coupons
                  </p>
                </Link>
              }

              {tracker && ["roseburg", "hawaii"].includes(tracker.name) &&
                <Link to={trackerSubdomain ? "/advertise" : `/t/${tracker.name}/advertise`} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Advertise
                  </p>
                </Link>
              }

              {tracker && tracker.name === "hawaii" &&
                <div className="position-relative px-3 py-2 mb-2 main-ui-nav-overlay-item">
                  <Menu>
                    <Menu.Button className="border-0 bg-transparent text-muted transition-color p-0 position-relative d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /></svg>

                      <p className="mb-0 font-weight-medium text-dark">
                        Monitoring
                      </p>
                    </Menu.Button>

                    <Menu.Items className="mx-3 headless-ui-dropdown border w-56 d-flex flex-column position-absolute left-0 mt-2 bg-white rounded">
                      <Menu.Item className="px-3 py-2 my-2 d-block font-weight-medium medium text-dark">
                        <span>
                          Monitoring
                        </span>
                      </Menu.Item>

                      <hr className="w-100 mt-0 mb-2" />

                      <div className="pb-2">
                        <Menu.Item className="px-3 py-2 text-decoration-none d-block text-dark medium ">
                          <Link to={trackerSubdomain ? "/monitoring/webcams" : "/t/hawaii/monitoring/webcams"}>
                            Webcams
                          </Link>
                        </Menu.Item>

                        <Menu.Item className="px-3 py-2 text-decoration-none d-block text-dark medium ">
                          <Link to={trackerSubdomain ? "/monitoring/tilt" : "/t/hawaii/monitoring/tilt"}>
                            Tilt
                          </Link>
                        </Menu.Item>

                        <Menu.Item className="px-3 py-2 text-decoration-none d-block text-dark medium ">
                          <Link to={trackerSubdomain ? "/monitoring/2-year-gps" : "/t/hawaii/monitoring/2-year-gps"}>
                            2 Year GPS
                          </Link>
                        </Menu.Item>

                        <Menu.Item className="px-3 py-2 text-decoration-none d-block text-dark medium ">
                          <Link to={trackerSubdomain ? "/monitoring/10-year-gps" : "/t/hawaii/monitoring/10-year-gps"}>
                            10 Year GPS
                          </Link>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>
              }

              {tracker && ["roseburg", "hawaii"].includes(tracker.name) &&
                <Link to={trackerSubdomain ? "/support" : `/t/${tracker.name}/support`} className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Donate
                  </p>
                </Link>
              }

              {user &&
                <Link to="/settings" className="text-decoration-none px-3 py-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Settings
                  </p>
                </Link>
              }

              {user && <hr className="w-100" />}

              {user &&
                <a href="/api/users/current/logout" className="text-decoration-none px-3 py-2 mb-2 main-ui-nav-overlay-item text-muted d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="img-icon-xxs mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>

                  <p className="mb-0 font-weight-medium text-dark">
                    Logout
                  </p>
                </a>
              }
            </div>
          </Swipe>
        </div>
      </>
    );
  }
}

export default MobileNavFlyout;
